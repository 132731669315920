import { useQuery } from '@apollo/client'
import { ActionsPopupMenu, Table } from '@components'
import { EmptyTable, Input, MobileGolfClubCard, Pagination, StatusIcon } from '@elements'
import { golfItems as graphqlGolfItems, user as graphqlUser } from '@graphql'
import { useGraphqlGolfClub, useGraphqlGolfEquipment, useWindowSize } from '@hooks'
import PaginationArrow from '@images/collapse-arrow.svg'
import SearchImage from '@images/search.svg'
import Dots from '@images/three-vertical-dots.svg'
import { AccountPage } from '@pages/components'

import { AccountLayout } from '@layouts'
import {
  GolfItem,
  PaginatedGolfItems,
  PaginationArgs,
  PaginationType,
  ResponseType,
  TableColumnType,
  User
} from '@types'
import { addDays, formatDateTimeString } from '@utils'
import { navigate } from 'gatsby'
import React, { useCallback, useState } from 'react'
import { useAlert } from 'react-alert'
import PopupComponent from 'reactjs-popup'
import './my-golf-club-inventory.scss'
import { debounce } from 'lodash'

interface AccountMyGolfClubInventoryProps {}

const AccountMyGolfClubInventory: React.FC<AccountMyGolfClubInventoryProps> = () => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { deleteGolfClub, extendGolfClub, republishGolfClub } = useGraphqlGolfClub()
  const { deleteGolfEquipment, extendGolfEquipment } = useGraphqlGolfEquipment()

  const alert = useAlert()
  const [term, setTerm] = useState('')

  const { data } = useQuery<ResponseType<User>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const { data: dataPaginatedGolfItems, refetch, loading } = useQuery<ResponseType<PaginatedGolfItems>, PaginationArgs>(
    graphqlGolfItems.PaginatedGolfItems,
    {
      variables: { pageSize: 10 }
    }
  )
  const content = dataPaginatedGolfItems?.res.entries || []
  const currentUser = data && data.res
  const currentUserPaymentCard = currentUser?.defaultPaymentCard
  const paginatedGolfItems = dataPaginatedGolfItems?.res
  const paginationTotalPages = dataPaginatedGolfItems?.res.totalPages

  if (!loading && !currentUser) navigate('/home/')
  if (!currentUser) return null

  const pagination: PaginationType = {
    totalPages: paginatedGolfItems?.totalPages || 1,
    totalEntries: paginatedGolfItems?.totalEntries || 1,
    pageSize: paginatedGolfItems?.pageSize || 1,
    pageNumber: paginatedGolfItems?.pageNumber || 1
  }

  const sortedContent: GolfItem[] = content.slice().sort((a: GolfItem, b: GolfItem) => {
    const validFirstDate = Date.parse(a.updatedAt)
    const validSecondDate = Date.parse(b.updatedAt)
    return validSecondDate - validFirstDate
  })

  const handleSearch = useCallback(
    debounce(val => refetch({ term: val }), 200),
    []
  )

  /**
   * Delete golfItem from my-golf-inventory
   */
  const handleDelete = async (golfItem: GolfItem) => {
    const { id, type } = golfItem

    if (type === 'GOLF_CLUB') await deleteGolfClub(id)
    else await deleteGolfEquipment(id)
  }

  /**
   * Extend expored golfItem
   */
  const handleExtend = async (golfItem: GolfItem) => {
    const { id, type } = golfItem
    const expiresAt = addDays(new Date(), 10).toISOString().split('T')[0]

    if (type === 'GOLF_CLUB') await extendGolfClub(id, expiresAt)
    else await extendGolfEquipment({ id, expiresAt })
  }

  /**
   * Republish golfItem
   */
  const handleRepublish = async (golfItem: GolfItem) => {
    const { id } = golfItem
    const date = addDays(new Date(), 10).toISOString().split('T')[0]
    const userCardId = currentUserPaymentCard?.id

    if (!userCardId) {
      alert.show(`Error: payment card not found.`, {
        type: 'error'
      })
      return null
    }

    if (golfItem && userCardId) {
      await republishGolfClub(id, date, userCardId)
    }
  }

  /**
   * Render golfItem status
   */
  const renderStatus = (status: string) => {
    switch (status) {
      case 'ON_SALE':
        return <StatusIcon status={'For Sale'} />
      case 'SOLD':
        return <StatusIcon status={'Sold'} />
      case 'UNSOLD':
        return <StatusIcon status={'Expired'} />
      case 'WAIT_FOR_PAYMENT':
        return <StatusIcon status={'in Processing'} />
      case 'TAXES_PAYMENT_ERROR':
        return <StatusIcon status={'Cancelled'} />

      default:
        return <StatusIcon status={'Sold'} />
    }
  }

  /**
   * Мобильный попап контрол меню
   */
  const renderMobilePopup = (golfItem: GolfItem) => {
    const status = golfItem.status + ''

    if (status === 'SOLD') {
      return null
    }

    return (
      <PopupComponent
        trigger={
          <div className={'profile-payment-header-cards-item-view-actions'}>
            <Dots />
          </div>
        }
        modal
        overlayStyle={{ background: 'rgba(82, 82, 82, 0.61)' }}
        contentStyle={{ background: 'transparent', width: '100%', marginBottom: '0' }}
      >
        {(close: () => void) => {
          return (
            <div style={{ width: '300px', margin: '0 auto', fontSize: '18px' }}>
              <div
                style={{
                  background: '#ffffff',
                  borderRadius: '14px',
                  boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.14)'
                }}
              >
                {status === 'UNSOLD' && (
                  <div
                    style={{
                      padding: '19px 50px',
                      textAlign: 'center',
                      color: '#0C0C0C',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      close()
                      handleExtend(golfItem)
                    }}
                  >
                    Extend
                  </div>
                )}
                {status === 'TAXES_PAYMENT_ERROR' && (
                  <div
                    style={{
                      padding: '19px 50px',
                      textAlign: 'center',
                      color: '#0C0C0C',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      close()
                      handleRepublish(golfItem)
                    }}
                  >
                    Republish
                  </div>
                )}
                <div
                  style={{
                    padding: '19px 50px',
                    textAlign: 'center',
                    color: '#F9655B',
                    borderTop: status === 'UNSOLD' ? '1px solid #EFEFF9' : '',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    close()
                    handleDelete(golfItem)
                  }}
                >
                  Delete
                </div>
              </div>
              <div
                style={{
                  background: '#ffffff',
                  padding: '19px 50px',
                  marginTop: '10px',
                  borderRadius: '14px',
                  boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.14)',
                  textAlign: 'center',
                  cursor: 'pointer'
                }}
                onClick={close}
              >
                Cancel
              </div>
            </div>
          )
        }}
      </PopupComponent>
    )
  }

  /**
   * Render status icon
   */
  const renderActionStatus = (golfItem: GolfItem) => {
    const status = golfItem.status

    return (
      <div className={'account-my-golf-club-inventory-table-card-status'}>
        {renderStatus(status + '')}
        {renderMobilePopup(golfItem)}
      </div>
    )
  }

  /**
   * Рендер опшинов контрол меню
   */
  const renderControls = (_: unknown, golfItem: GolfItem) => {
    const status = golfItem.status + ''

    const getControlsMenu = (status: string) => {
      switch (status) {
        case 'TAXES_PAYMENT_ERROR':
          return golfItemRepublishMenu
        case 'UNSOLD':
          return golfItemExtendMenu
        case 'ON_SALE':
          return golfItemDeleMenu
        default:
          return golfItemDeleMenu
      }
    }

    const golfItemRepublishMenu = [
      {
        label: 'Re-open',
        value: '',
        icon: '/assets/images/reload.svg',
        onClick: () => handleRepublish(golfItem)
      },
      {
        label: 'Delete',
        value: '',
        icon: '/assets/images/actions-delete.svg',
        onClick: () => handleDelete(golfItem)
      }
    ]

    const golfItemExtendMenu = [
      {
        label: 'Extend',
        value: '',
        icon: '/assets/images/reload.svg',
        onClick: () => handleExtend(golfItem)
      },
      {
        label: 'Delete',
        value: '',
        icon: '/assets/images/actions-delete.svg',
        onClick: () => handleDelete(golfItem)
      }
    ]

    const golfItemDeleMenu = [
      {
        label: 'Delete',
        value: '',
        icon: '/assets/images/actions-delete.svg',
        onClick: () => handleDelete(golfItem)
      }
    ]

    if (status === 'SOLD') {
      return <></>
    }

    if (status === 'WAIT_FOR_PAYMENT') {
      return <></>
    }

    return <ActionsPopupMenu options={getControlsMenu(status)} />
  }

  const handleMyOfferBookPagination = (page: number) => {
    refetch({ term: term, page })
  }

  /**
   * Table columns
   */
  const columns: TableColumnType[] = [
    {
      title: 'Item',
      key: 'name',
      render(_, golfItem) {
        return <>{golfItem?.modelTitle || '-'}</>
      }
    },
    {
      title: 'Brand',
      key: 'brand',
      render(_, golfItem) {
        return <>{golfItem?.brandTitle || '-'}</>
      }
    },
    {
      title: 'Category',
      key: 'category.name',
      render(_, golfItem) {
        return <>{golfItem?.categoryTitle || '-'}</>
      }
    },
    {
      title: 'price',
      key: 'price',
      render(price: number) {
        return <>{price ? '$' + price : '-'}</>
      }
    },
    {
      title: 'List Date',
      key: 'insertedAt',
      render(insertedAt: string) {
        const { formattedDate: formattedUpdatedDate, formattedTime: formattedUpdatedTime } = formatDateTimeString(
          insertedAt
        )
        return (
          <>
            {formattedUpdatedDate}
            <br />
            {formattedUpdatedTime}
          </>
        )
      }
    },
    {
      title: 'Expire Date',
      key: 'expiresAt',
      render(expired: string) {
        const { formattedDate: formattedExpireDate } = formatDateTimeString(expired)

        return <>{formattedExpireDate}</>
      }
    },
    {
      title: 'Status',
      key: 'status',
      render: renderStatus
    },
    {
      title: '',
      key: 'menu',
      render: renderControls
    }
  ]

  return (
    <AccountLayout pageClassName={'account-my-golf-club-inventory'} hideLayout={isMobileScreen}>
      <h6 className={'account-layout-page-title'}>MY GOLF INVENTORY</h6>
      <div className={'account-my-golf-club-inventory-header'}>
        {/* <div>
          <ButtonWithPlus onClick={() => navigate('/account/place-a-club')}>Add a Club</ButtonWithPlus>
        </div> */}
      </div>
      <div className={'account-my-golf-club-inventory-navigation'}>
        <Input
          placeholder={'Search your listings'}
          value={term}
          onChange={val => {
            handleSearch(val)
            setTerm(val)
          }}
          onTimesClick={async () => {
            setTerm('')
            await refetch({ term: '' })
          }}
          prefix={<SearchImage />}
        />
        <AccountPage.GolfInventoryTopPagination pagination={pagination} term={term} refetch={refetch} />
      </div>
      <div className={'account-my-golf-club-inventory-table'}>
        {isMobileScreen &&
          (!sortedContent.length ? (
            <EmptyTable title={loading ? 'Loading Your Golf Inventory . . .' : 'Your Golf Inventory is Empty'} />
          ) : (
            sortedContent.map(golfItem => {
              const title = golfItem?.modelTitle || ''
              const avatar = golfItem?.modelAvatar || ''

              const { formattedDate: formattedUpdateDate, formattedTime: formattedUpdateTime } = formatDateTimeString(
                golfItem.updatedAt
              )

              const { formattedDate: formattedExpiredDate } = formatDateTimeString(golfItem.expiresAt)
              const data = [
                {
                  label: 'Brand',
                  value: golfItem?.brandTitle || '-'
                },
                {
                  label: 'Category',
                  value: golfItem?.categoryTitle || '-'
                },
                {
                  label: 'Price',
                  value: golfItem?.price ? `$${golfItem.price}` : '-'
                },
                {
                  label: 'List Date',
                  value: `${formattedUpdateDate} ${formattedUpdateTime}`
                },
                {
                  label: new Date(golfItem.expiresAt) > new Date() ? 'Expires' : 'Expired',
                  value: `${formattedExpiredDate} `
                }
              ]

              return (
                <MobileGolfClubCard
                  key={golfItem.id}
                  title={title}
                  avatar={avatar}
                  data={data}
                  subtitle={renderActionStatus(golfItem)}
                  footer={
                    <div
                      className={'account-my-golf-club-inventory-table-card-footer'}
                      onClick={() => {
                        if (golfItem.id) {
                          const itemTypeHash = golfItem.type === 'GOLF_CLUB' ? 'golfClub' : 'golfEquipment'
                          navigate(`/account/store/my-golf-club-inventory/${golfItem.id}/details#${itemTypeHash}`)
                        }
                      }}
                    >
                      More
                      <PaginationArrow />
                    </div>
                  }
                />
              )
            })
          ))}
        {!isMobileScreen &&
          (!sortedContent.length ? (
            <EmptyTable title={loading ? 'Loading Your Golf Inventory . . .' : 'Your Golf Inventory is Empty'} />
          ) : (
            <Table
              columns={columns}
              rows={sortedContent}
              handleRowClick={golfItem => {
                const itemTypeHash = golfItem.type === 'GOLF_CLUB' ? 'golfClub' : 'golfEquipment'

                navigate(`/account/store/my-golf-club-inventory/${golfItem.id}/details#${itemTypeHash}`)
              }}
            />
          ))}
        {(paginationTotalPages || 0) < 2 ? null : <Pagination {...pagination} onClick={handleMyOfferBookPagination} />}
      </div>
    </AccountLayout>
  )
}

export default AccountMyGolfClubInventory
